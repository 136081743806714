.textarea {
    width: 100% !important;
    height: 100%;
    box-sizing: border-box;
    border-radius: 10px !important;
    margin-top: 16px;
    margin-bottom: 8px;
    background: transparent;
    border-color: #0098d1;
}
