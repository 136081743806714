@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;700;900&family=Roboto:wght@400;700;900&family=Source+Sans+3:wght@200;300;400&display=swap');

.pac-target-input {
    height: auto;
    min-height: 1.4375em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.pac-container {
    list-style: none;
    margin: 0px;
    padding: 8px 0px;
    position: relative;
    outline: 0px;
}

.pac-item {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    color: inherit;
    font-family: 'Fira Sans', Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    min-height: 48px;
    padding: 6px 16px;
    box-sizing: border-box;
    white-space: nowrap;
    span,
    .pac-item-query {
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        z-index: 0;
        inset: 0px;
        border-radius: inherit;
    }
}
