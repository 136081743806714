.selectHasError {
    .form__form-group-file {
        .file__placeholder {
            border-color: $error-color !important;
        }
    }
    .react-select__control {
        border: solid 1.5px $error-color !important;
    }
}
.form__form-group-file {
    display: flex;
    width: 90%;

    label {
        width: 100%;
        padding: 30px 20px;
        cursor: pointer;
        transition: all 0.3s;
        text-align: center;
        height: 100px;
        font-weight: 600;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none !important;
        border-radius: 10px;
        border: dashed 2px $secondary-color !important;

        // background-color: $secondary-color !important;
        color: $secondary-color;
        font-size: 1rem;
        // &:hover {
        //   background-color: $secondary-color !important;
        //   color: $secondary-text;
        // }
        &.uploaded {
            border: solid 1.5px $secondary-color !important;
            color: #fff;
            background-color: $secondary-color !important;
        }

        /*&:hover {
  
      }*/
    }
    .file__placeholder {
        width: 80%;
        display: flex;
        align-items: center;
        border: solid 1.5px $secondary-color;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        padding: 0 20px 0 10px;
        height: 130px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.00938em;
        overflow: hidden;
    }

    span {
        padding-left: 0;
        color: $secondary-text;
        font-weight: 400;
    }

    input {
        display: none;
    }
    .fileuploader {
        word-wrap: break-word !important;
        overflow-wrap: break-word !important;
        width: 250px !important;
    }
    .fileuploader * {
        word-wrap: break-word !important;
        overflow-wrap: break-word !important;
    }
}
