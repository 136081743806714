/**
Base colors
*/
// $primary-color: #e24511;
// $ethiasDark-color: #004A47;
// $ethiasLight-color: #22B9D8;
$ethiasGray-color: #F2F2F4;
$ethiasBlack-color: #111111;
$ethiasBlackLight-color: #898989;

$primary-color: #e24511;
$secondary-color: #0098d1;
$error-color: #B30000;
$success-color: #008000;
$warning-color: #F58221;


$passed: #B2D135;
$active: #56BDEA;
$warning: #ed6c02;
$black-color: #111111;
// $primary-color: #009793;
// $secondary-color: #009793;
$primary-border: 1px solid rgba(221, 221, 221, 0.8666666667);

$primary-text: white;
$secondary-text: black;

$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

