@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;700;900&family=Roboto:wght@400;700;900&family=Source+Sans+3:wght@200;300;400&display=swap');

.MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
    font-family: 'Fira Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}
.Mui-error fieldset {
    background-color: rgb(211 47 47 / 6%);
}
